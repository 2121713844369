<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-subheader>
        견적서 상세보기
      </v-subheader>
      <v-sheet class="px-3 pb-0 d-flex" />
      <v-card>
        <v-card-text>
          <v-simple-table class="estimate-table">
            <template #default>
              <thead>
                <tr>
                  <th colspan="4" class="text-center py-5" :style="{ borderTop: '1px solid rgba(255, 255, 255, 0.12)' }">
                    <h1>
                      견 &nbsp; 적 &nbsp; 서
                    </h1>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colspan="4" class="text-right text-body-2">
                    {{ estimate.registerDate ? $parseDateKo(estimate.registerDate) : '년 월 일' }}
                  </th>
                </tr>
                <tr>
                  <th colspan="4" class="text-right text-body-2">
                    {{ estimate.manager ? estimate.manager + ' 담당자 귀하' : '담당자 정보없음' }}
                  </th>
                </tr>
                <tr>
                  <th colspan="4" class="text-right text-body-2">
                    아래와 같이 견적합니다.
                  </th>
                </tr>
                <tr>
                  <th colspan="1" class="text-center">
                    상품명
                  </th>
                  <td colspan="3" class="text-center text-body-2">
                    {{ estimate.productName ? estimate.productName : '상품정보 없음' }}
                  </td>
                </tr>
                <tr>
                  <th colspan="1" class="text-center" :style="{ borderBottom: '1px solid rgba(255, 255, 255, 0.12)' }">
                    스토어
                  </th>
                  <td colspan="3" class="text-center text-body-2" :style="{ borderBottom: '1px solid rgba(255, 255, 255, 0.12)' }">
                    {{ estimate.productStore ? estimate.productStore : '상품정보 없음' }}
                  </td>
                </tr>
                <tr>
                  <th colspan="1" class="text-center">
                    키워드
                  </th>
                  <td colspan="3" class="text-center text-body-2">
                    {{ estimate.productKeyword ? estimate.productKeyword : '상품정보 없음' }}
                  </td>
                </tr>
                <tr>
                  <th colspan="1" class="text-center" :style="{ borderBottom: '1px solid rgba(255, 255, 255, 0.12)' }">
                    시작순위
                  </th>
                  <td colspan="3" class="text-center text-body-2" :style="{ borderBottom: '1px solid rgba(255, 255, 255, 0.12)' }">
                    {{ estimate.startOrder ? estimate.startOrder : '-' }}
                  </td>
                </tr>
                <tr>
                  <th class="text-truncate text-center">
                    상세옵션명
                  </th>
                  <th class="text-truncate text-center">
                    진행일자
                  </th>
                  <th class="text-truncate text-center">
                    인원
                  </th>
                  <th class="text-truncate text-center">
                    제품비
                  </th>
                </tr>
                <template v-if="estimate.items && estimate.items.length > 0">
                  <tr v-for="(item, i) in estimate.items" :key="'estimate-item-' + i">
                    <td class="text-truncate text-center text-body-2" :style="{ height: '30px' }">
                      {{ item.itemName ? item.itemName : '옵션정보 없음' }}
                    </td>
                    <td class="text-truncate text-center text-body-2" :style="{ height: '30px' }">
                      {{ item.itemTerm ? item.itemTerm : '옵션정보 없음' }}
                    </td>
                    <td class="text-truncate text-center text-body-2" :style="{ height: '30px' }">
                      {{ item.itemPersonnel ? item.itemPersonnel.toLocaleString() : '옵션정보 없음' }}
                    </td>
                    <td class="text-truncate text-center text-body-2" :style="{ height: '30px' }">
                      {{ item.itemPrice ? item.itemPrice.toLocaleString() : '옵션정보 없음' }}
                    </td>
                  </tr>
                  <tr v-for="i in 8 - estimate.items.length" :key="'estimate-blank-' + i">
                    <td class="text-truncate text-center text-body-2" :style="{ height: '30px' }" />
                    <td class="text-truncate text-center text-body-2" :style="{ height: '30px' }" />
                    <td class="text-truncate text-center text-body-2" :style="{ height: '30px' }" />
                    <td class="text-truncate text-center text-body-2" :style="{ height: '30px' }" />
                  </tr>
                </template>
                <tr>
                  <th class="text-truncate text-center">
                    택배비용
                  </th>
                  <td class="text-truncate text-center text-body-2">
                    {{ estimate.deliveryCost != null && estimate.deliveryCost === 0 ? '무료' : '' }}
                  </td>
                  <td class="text-truncate text-center text-body-2" />
                  <td class="text-truncate text-center text-body-2">
                    {{ estimate.deliveryCost ? estimate.deliveryCost.toLocaleString() : '0' }}
                  </td>
                </tr>
                <tr>
                  <th class="text-truncate text-center">
                    대리배송
                  </th>
                  <td class="text-truncate text-center text-body-2">
                    {{ estimate.proxyDeliveryTerm ? estimate.proxyDeliveryTerm : '대리배송 정보없음' }}
                  </td>
                  <td class="text-truncate text-center text-body-2">
                    {{ estimate.proxyDeliveryPersonnel ? estimate.proxyDeliveryPersonnel.toLocaleString() : '-' }}
                  </td>
                  <td class="text-truncate text-center text-body-2">
                    {{ estimate.proxyDeliveryPrice ? estimate.proxyDeliveryPrice.toLocaleString() : '0' }}
                  </td>
                </tr>
                <tr>
                  <th colspan="4">
                    <div class="d-flex">
                      <span>
                        총 합계
                      </span>
                      <v-spacer />
                      <span class="text-body-2">
                        {{ estimate.totalPrice ? estimate.totalPrice.toLocaleString() : '0' }}
                      </span>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th colspan="4" :style="{ height: '25px' }" />
                </tr>
                <tr>
                  <th class="text-truncate text-center">
                    건당비용
                  </th>
                  <td class="text-truncate text-center text-body-2">
                    {{ estimate.costPerIncidentTerm ? estimate.costPerIncidentTerm : '건당비용 정보없음' }}
                  </td>
                  <td class="text-truncate text-center text-body-2">
                    {{ estimate.costPerIncidentPersonnel ? estimate.costPerIncidentPersonnel.toLocaleString() : '-' }}
                  </td>
                  <td class="text-truncate text-center text-body-2">
                    {{ estimate.costPerIncidentPrice ? estimate.costPerIncidentPrice.toLocaleString() : '0' }}
                  </td>
                </tr>
                <tr>
                  <th class="text-truncate text-center">
                    건당비용 부가세
                  </th>
                  <td class="text-truncate text-center text-body-2" />
                  <td class="text-truncate text-center text-body-2" />
                  <td class="text-truncate text-center text-body-2">
                    {{ estimate.feeCostPerIncident ? estimate.feeCostPerIncident.toLocaleString() : '0' }}
                  </td>
                </tr>
                <tr>
                  <th colspan="4">
                    <div class="d-flex">
                      <span>
                        총 합계
                      </span>
                      <v-spacer />
                      <span class="text-body-2">
                        {{ estimate.totalCostPerIncident ? estimate.totalCostPerIncident.toLocaleString() : '0' }}
                      </span>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th colspan="4" :style="{ height: '25px' }" />
                </tr>
                <tr>
                  <th rowspan="2" class="text-truncate">
                    결제방식
                  </th>
                  <td class="text-center">
                    선결재
                  </td>
                  <td class="text-center">
                    일결재
                  </td>
                  <td class="text-center">
                    부분결재
                  </td>
                </tr>
                <tr>
                  <td class="text-center">
                    {{ estimate.payType === '선결재' ? 'O' : '' }}
                  </td>
                  <td class="text-center">
                    {{ estimate.payType === '일결재' ? 'O' : '' }}
                  </td>
                  <td class="text-center">
                    {{ estimate.payType === '부분결재' ? 'O' : '' }}
                  </td>
                </tr>
                <tr>
                  <th colspan="4" class="text-center">
                    비고
                  </th>
                </tr>
                <tr>
                  <th colspan="4" class="text-left" :style="{ borderBottom: '1px solid rgba(255, 255, 255, 0.12)' }">
                    {{ estimate.memo }}
                  </th>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="downloadEstimate"
          >
            견적서 다운로드
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ManageEstimateDetailContent',
    props: {
      estimate: {},
    },
    data () {
      return {
      }
    },
    computed: {
      estimateIdx () {
        return this.$route.query.estimateIdx
      },
    },
    methods: {
      downloadEstimate () {
        const url = `manage/estimate/${this.estimate.idx}/download`
        this.$axios.get(url, {
          responseType: 'blob',
        }).then(res => {
          if (res.data && res.data.size > 0) {
            const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }))
            const fileName = this.estimate.company.companyName + '_견적서_' + this.estimate.idx + '.xlsx'
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
          } else {
            alert('파일 다운로드에 실패하였습니다.')
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .estimate-table::v-deep {
    th, td {
      &:first-child {
        border-left: 1px solid rgba(255, 255, 255, 0.12) !important;
      }
      border-right: 1px solid rgba(255, 255, 255, 0.12) !important;
    }
  }

</style>
