<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <ManageEstimateDetailHeader />
      <ManageEstimateDetailContent :estimate="estimate" />
    </v-container>
  </v-lazy>
</template>

<script>
  import ManageEstimateDetailHeader from '@/views/manage/estimate/view/Header'
  import ManageEstimateDetailContent from '@/views/manage/estimate/view/Content'

  export default {
    name: 'EstimateDetail',
    components: { ManageEstimateDetailContent, ManageEstimateDetailHeader },
    data () {
      return {
        estimate: {},
      }
    },
    computed: {
      estimateIdx () {
        return this.$route.params.estimateIdx
      },
    },
    beforeMount () {
      this.getEstimate()
    },
    methods: {
      async getEstimate () {
        const url = `/manage/estimate/${this.estimateIdx}`
        await this.$axios.get(url).then(res => {
          if (res && res.data) {
            this.estimate = res.data
          }
        })
      },
    },
  }
</script>

<style scoped>

</style>
